import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Tooltip, Card, Grid, TextField, Button, Typography, Modal } from '@mui/material';
import SeasonDialog from './SeasonDialog';
import { useRecoilState } from 'recoil';
import { seasonDialogOpenState, SeasonListState } from '../atoms/atoms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { VariantType,useSnackbar } from 'notistack';
import * as pomelo from "pomelo-sdk";
import { loadAllSeasons } from '../utils/apihelpers';
import {SeasonInfo} from '../utils/interfaces';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)({
    "& .MuiDataGrid-cell:focus":
    {
        outline: "none",
    },
});

const SEASONS_PER_PAGE = 50;

export default function SeasonsTable() {
    const [seasonDialogOpen, setSeasonDialogOpen] = useRecoilState(seasonDialogOpenState);
    const [selectedSeason, setSelectedSeason] = useState<any>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [seasonList, setSeasonList] = useRecoilState(SeasonListState);
    const [seasonsToDisplay, setSeasonsToDisplay] = useState<Array<SeasonInfo>>(seasonList);
    const [searchSeason, setSearchSeason] = useState<any>('');

    const columns: GridColDef[] = [
        {
            field: 'season_id',
            headerName: '#',
            width: 100,
            renderCell: (params: any) =>  (
                <span>
                    { params.row.season_id }
                </span>
            )
        },
        {
            field: 'title',
            headerName: 'Season Title',
            width: 250,
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.title} >
                        <span>{ params?.row?.title }</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 180,
            renderCell: (params: any) =>  (
                <span >
                    { params.row.description }
                </span>
            )
        },
        {
            field: 'match_value',
            headerName: 'Value',
            width: 180,
            renderCell: (params: any) =>  (
                <span >
                    ${ params.row.match_value }
                </span>
            )
        },
        {
            field: 'matching_rounds',
            headerName: 'Rounds',
            width: 130,
            renderCell: (params: any) =>  (
                <span >
                    { params.row.matching_rounds?.length ?? 0 }
                </span>
            )
        },
        {
            field: 'submissions_start_at',
            headerName: 'Submissions Start',
            width: 200,
        },
        {
            field: 'submissions_end_at',
            headerName: 'Submissions End',
            width: 200,
        },
        {
            field: 'start_at',
            headerName: 'Start',
            width: 200,
        },
        {
            field: 'end_at',
            headerName: 'End',
            width: 200,
        },
        {
            field: 'finalized_at',
            headerName: 'Finalized',
            width: 200,
        },
    ];

    useEffect(() => {
        console.log('seasonDialogOpen changed', seasonDialogOpen)
        if (!seasonDialogOpen) {
            init();
        }
    }, [seasonDialogOpen])

    useEffect(() => {
        filterSeasons(searchSeason)
    }, [seasonList])


    const handleClickOpen = (e: any) => {
        console.log(e.row);
        setSelectedSeason(e.row);
        setSeasonDialogOpen(true);
    };

    const handleSearchSeason = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchSeason(event.target.value)
        filterSeasons(event.target.value)
    }

    const filterSeasons = (searchToken: string) => {
        const token = searchToken.toLowerCase()
        const seasons = seasonList.filter((season: any) =>
            ((`${season.id}`).toLowerCase().includes(token) ||
            season.title?.includes(token) ||
            season.description?.includes(token))
        ) ?? []
        setSeasonsToDisplay( seasons )
    }

    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }

    const handleCreateSeason = () => {

        flashMessage(`TODO!`, "error");
    }

    const init = async () => {

        try {
            const [ seasons, chainRounds ] = await Promise.all([
                loadAllSeasons(),
                pomelo.get_rounds()
            ].map(p => p.catch(e => null)));
            console.log('seasons', { seasons, chainRounds })
            if(!chainRounds) flashMessage('Failed to load chain rounds', "warning");
            if(seasons === null) flashMessage('Failed to load seasons', "warning");

            const reducedSeasons: any = (seasons ?? []).reduce((prevValue: any, season: any) => {
                season.id = season.season_id;
                return [...prevValue, season]
            }, [])

            console.log('reducedSeasons', { reducedSeasons })
            setSeasonList(reducedSeasons)
        }
        catch (err) {
            flashMessage('Failed to load seasons', "error");
        }
    }

    return <div style={{ height:"calc(100vh - 64px)" }}>
            <SeasonDialog season={selectedSeason} />
            <Grid container display="flex" flexDirection="column">
                <Grid item style={{ width: '100%', margin: "auto", padding: "0px 30px 0px 30px" }}>
                    <Typography variant={"h4"} style={{ fontSize: "30px", fontWeight: 400, padding: "24px", color: "#021A4D" }}>Pomelo Seasons</Typography>
                    <Grid container>
                        <Grid item style={{ paddingLeft: "16px" }}>
                            <TextField id="outlined-search-season-id" label="Search Seasons" variant="outlined" value={searchSeason} onChange={handleSearchSeason}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Card style={{ margin: "20px 30px" }} >
                    <Grid item style={{ cursor: 'pointer', height: 750, width: '100%', margin: "auto" }}>
                        <StyledDataGrid
                            rows={seasonsToDisplay}
                            columns={columns}
                            pageSize={SEASONS_PER_PAGE}
                            rowsPerPageOptions={[SEASONS_PER_PAGE]}
                            onRowClick={(e) => handleClickOpen(e)}
                            disableSelectionOnClick
                            componentsProps={{ panel: {as: Modal}}}
                        />
                    </Grid>
                    <div style={{ textAlign: "center", padding: "20px 0" }}>
                        <Button variant="contained" style={{ backgroundColor: "#18AB6A", borderRadius: "25px", margin: "5px" }} startIcon={<AddCircleIcon />} onClick={handleCreateSeason}>
                            Create Season
                        </Button>
                    </div>
                </Card>

            </Grid>
        </div>
}