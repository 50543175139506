import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DrawerData } from "./DrawerData";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    list: {
        width: "100%",
        height: "50px",
        display: "flex",
        cursor: "pointer",
        margin: "8px 0px",
        color: "#616161",
        justifyContent: "flex-start",
        "&:hover": {
            backgroundColor: "#adadad3b",
            borderRadius: "12px",
            color: "#021A4D"
        },
        "&#active": {
            // backgroundColor: "#FBEFDF",
            backgroundColor: "#adadad3b",
            borderRadius: "12px",
            color: "#021A4D"

        }

    }

})
const NavDrawer = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    // const location = useLocation();

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    {/* <LogoSection /> */}
                    {/* Hiiii */}
                </Box>
            </Box>
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                <ul style={{ padding: 0, width: "100%", listStyleType: "none" }}>
                    {DrawerData.map((item: any, index: number) => {
                        return <Button key={index} className={classes.list} id={window.location.pathname === item.path ? "active" : ""}
                            onClick={() =>
                                navigate(item.path)
                            }>
                            <div style={{ margin: "auto 0", padding: "0px 16px", display: "flex" }}>{item.icon}</div>
                            <div style={{ margin: "auto 0" }}>{item.title}</div>
                        </Button>
                    })}

                </ul>
                {/* <MenuList />
                <MenuCard /> */}
            </PerfectScrollbar>
        </>
    );

    //const container = window !== undefined ? () => window.document.body : undefined;
    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? 260 : 'auto' }} aria-label="mailbox folders">
            <Drawer
                // container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={true}
                // onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 260,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '64px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
    // return (
    //   <>
    //     {/* logo & toggler button */}
    //     <Box
    //       sx={{
    //         width: 228,
    //         display: 'flex',
    //         [theme.breakpoints.down('md')]: {
    //           width: 'auto'
    //         }
    //       }}
    //     >
    //       <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
    //         <LogoSection />
    //       </Box>
    //       <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
    //         {/* <Avatar
    //           variant="rounded"
    //           sx={{
    //             ...theme.typography.commonAvatar,
    //             ...theme.typography.mediumAvatar,
    //             transition: 'all .2s ease-in-out',
    //             background: theme.palette.secondary.light,
    //             color: theme.palette.secondary.dark,
    //             '&:hover': {
    //               background: theme.palette.secondary.dark,
    //               color: theme.palette.secondary.light
    //             }
    //           }}
    //           onClick={handleLeftDrawerToggle}
    //           color="inherit"
    //         >
    //           <Menu stroke={1.5} size="1.3rem" />
    //         </Avatar> */}
    //       </ButtonBase>
    //     </Box>

    //     {/* header search */}
    //     {/* <SearchSection /> */}
    //     <Box sx={{ flexGrow: 1 }} />
    //     <Box sx={{ flexGrow: 1 }} />

    //     {/* notification & profile */}
    //     {/* <NotificationSection />
    //       <ProfileSection /> */}
    //   </>
    // );
};

export default NavDrawer;