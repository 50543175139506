// src/components/authentication-button.js

import React, { useEffect } from "react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

import { useAuth0 } from "@auth0/auth0-react";
import localstorage from "../utils/localstorage";
import { useRecoilState } from "recoil";
import { isUserState } from "../atoms/atoms";

const AuthenticationButton = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [isUser, setIsUser] = useRecoilState(isUserState);

  const setAccessTokenInLocalStorage = async () => {
    const accessToken = await getAccessTokenSilently();
    localstorage.storeUserOnLocalStorage(isAuthenticated, user, accessToken);
  }

  useEffect(() => {
    if (isAuthenticated) {
      setIsUser(() => true);
      setAccessTokenInLocalStorage();

      const didRefresh: any = localStorage.getItem('didRefresh');
      if (didRefresh === "false" || didRefresh === null) {
        window.location.reload();
        localStorage.setItem('didRefresh', "true");
      }
    }
  }, [isAuthenticated])

  return isUser ? <LogoutButton /> : <LoginButton label={"Sign In"} />;
};

export default AuthenticationButton;
