import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TranslationsPanel from './TranslationsPanel';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { roundDialogOpenState } from '../atoms/atoms';
import { VariantType, useSnackbar } from 'notistack';
import { RoundInfo } from '../utils/interfaces';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPaper = styled(Dialog)({
    "& .MuiDialog-paper": {
        maxWidth: "100%"
    }
});


const GridDialogContent = styled(DialogContent)({
    borderRadius: '24px',
    display: 'grid',
    width: '90vw',
    maxWidth: '1200px',
    gridTemplateColumns: `5fr 4fr`,
    gridTemplateRows: 'auto',
    gridGap: '10px',
    gridTemplateAreas: `
        "logo info"
        "trans trans"
        "reports reports"`,
    '@media(max-width: 800px)': {
        gridTemplateColumns: `1fr`,
        gridTemplateAreas: `
            "logo"
            "info"
            "trans"
            "reports"`
    }
})


const LogoDiv = styled('div')({
    borderRadius: '24px',
    gridArea: 'logo',
    textAlign: 'center'
})

const InfoDiv = styled('div')({
    padding: "0px 24px 24px 24px",
    backgroundColor: "#f3f2f2",
    borderRadius: "24px",
    gridArea: 'info'
})

const InfoText = styled(Typography)({
    paddingTop: "16px"
})

const TranslationsDiv = styled('div')({
    padding: "0px 24px 24px 24px",
    backgroundColor: "#f3f2f2",
    borderRadius: "24px",
    gridArea: 'trans'
})

const LogoImg = styled('img')({
    maxWidth:'100%',
    maxHeight:'100%',
    borderRadius: '24px',
})

interface IRoundDialog {
    // open: boolean,
    round: RoundInfo,
}
const RoundDialog = ({ round }: IRoundDialog) => {
    const [roundDialogOpen, setRoundDialogOpen] = useRecoilState(roundDialogOpenState);
    const { enqueueSnackbar } = useSnackbar();
    const [roundDetails, setRoundDetails] = useState<any>(null);

    const handleClose = () => {
        setRoundDialogOpen(false);
    };

    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }

    const getRoundTranslations = () => ([
        {
            language: {
                code: 'en'
            },
            title: round.title,
            description: round.description,
            rules: round.rules,
        }
    ])


    useEffect(() => {
        if(!roundDialogOpen) return;
        getRoundDetails(round);
    }, [roundDialogOpen])



    const getRoundDetails = (round_id: any) => {
        if(!round_id) return;
        // getGrantInfo(grant.name).then((resp: any) => {
        //     setSeasonDetails(resp.data.data)
        //     console.log('🦐 grantDetails data: ', resp.data.data)
        // })
    }

    return (
        round !== null ? <div>
            <DialogPaper
                open={roundDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                style={{ borderRadius: "24px" }}
            >
                <DialogTitle>{round.title}</DialogTitle>
                <GridDialogContent >
                    <LogoDiv>
                        <LogoImg src={'https://dev.pomelo.io/_nuxt/img/grant-default-logo.7f2bbd0.svg'} />
                    </LogoDiv>
                    <InfoDiv>
                        <InfoText ><b>Round ID:</b> <span>{round.matching_round_id}</span></InfoText>
                        <InfoText ><b>Season ID:</b> <span>{round.season_id}</span></InfoText>
                        <InfoText ><b>Match Value:</b> <span >${round.amount_usd}</span></InfoText>
                        <InfoText ><b>Rounds:</b> <span >{round.approved_tokens.map((token: any) => token.symbol).join(', ')}</span></InfoText>
                        <InfoText ><b>Submissions Start:</b> <span >{round.submissions_start_at}</span></InfoText>
                        <InfoText ><b>Submissions End:</b> <span >{round.submissions_end_at}</span></InfoText>
                        <InfoText ><b>Donations Start:</b> <span >{round.start_at}</span></InfoText>
                        <InfoText ><b>Donations End:</b> <span >{round.end_at}</span></InfoText>
                        <InfoText ><b>Finalized:</b> <span >{round.finalized_at}</span></InfoText>
                    </InfoDiv>
                    <TranslationsDiv>
                        <TranslationsPanel translations={ getRoundTranslations()} />
                    </TranslationsDiv>
                </GridDialogContent>
            </DialogPaper>
        </div> : <></>
    );
}


export default RoundDialog;