// src/components/logout-button.js

import React, { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { localStorage } from '../utils/';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Divider from '@mui/material/Divider';

interface LogoutButtonProps {
  label?: string,
  type?: number,
  color?: string
}

const LogoutButton: FC<LogoutButtonProps> = ({ label, type, color }) => {
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    logout({
      returnTo: window.location.origin,
    });
    localStorage.removeUserFromLocalStorage();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Button
      variant={type === 1 ? "text" : "contained"} style={{ backgroundColor: "#021B4D", color: "#FFF", borderRadius: "24px" }}
      onClick={(event: React.MouseEvent<HTMLElement>) => { if(!open) setAnchorEl(event.currentTarget); }}
    >
      <PowerSettingsNew />&nbsp;
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigator.clipboard.writeText(`Bearer ${localStorage.getAuthSession()}`)}>
          <ListItemIcon>
            <ContentCopy fontSize="medium" />
          </ListItemIcon>
          Copy Token
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      { user?.email ?? ""}
    </Button>
  );
};

export default LogoutButton;
