import Axios from './Axios';

// BOUNTIES

export const getBountyList = (limit: number, pageNum: number): any => {
    // AVAILABLE STATUSES: DRAFT PENDING PUBLISHED DENIED BANNED RETIRED
    return Axios.get(`/admin/bounties?limit=${limit}&page=${pageNum}&admin_state[]=PENDING&admin_state[]=PUBLISHED&admin_state[]=DENIED&admin_state[]=BANNED&admin_state[]=RETIRED&sort%5B%5D=created_at%3Adesc`);
}

export const getBountySkills = (): any => {
    return Axios.get(`/bounties/skills`);
}

export const updateBountySkills = (newSkill:string): any => {
    return Axios.post(`/admin/bounties/skills`, { skill: newSkill });
}

export const syncBounties = (): any => {
    return Axios.post(`/admin/bounties/sync`);
}


// GRANTS
export const getGrantList = (limit: number, pageNum: number): any => {

    // AVAILABLE STATUSES: DRAFT PENDING PUBLISHED DENIED BANNED RETIRED
    return Axios.get(`/admin/grants?limit=${limit}&page=${pageNum}&admin_state[]=PENDING&admin_state[]=PUBLISHED&admin_state[]=DENIED&admin_state[]=BANNED&admin_state[]=RETIRED&sort%5B%5D=created_at%3Adesc`);

}

export const getGrantInfo = (grantName: string): any => {

    return Axios.get(`/grants/by_name/${grantName}`);

}

export const updateGrantState = (grantName: string, grantState: string, reason: string): any => {
    const body = {
        state: grantState,
        reason
    }

    return Axios.post(`/admin/grants/by_name/${grantName}/state`, body);

}

export const updateRoundState = (grantName: string, roundId: number, roundState: string, reason: string = '', force: boolean = false): any => {
    const body = {
        round_id: roundId,
        state: roundState,
        reason: reason || roundState,
        create_application: force
    }

    return Axios.post(`/admin/grants/by_name/${grantName}/round_state`, body);

}

export const syncGrant = (grantName: string): any => {
    return Axios.post(`/admin/grants/by_name/${grantName}/sync`);

}

export const getReports = (limit: number, pageNum: number): any => {
    return Axios.get(`/admin/abuse?limit=${limit}&page=${pageNum}`);
}

export const requestKYC = (): any => {
    return Axios.post(`/admin/users/kyc`);
}

export const syncGrants = (): any => {
    return Axios.post(`/admin/grants/sync`);
}

export const getUserList = (limit: number, pageNum: number) => {
    return Axios.get(`/admin/users?limit=${limit}&page=${pageNum}`);
}

export const getRoundList = (limit: number, pageNum: number) => {
    return Axios.get(`/rounds/?limit=${limit}&page=${pageNum}`);
}

export const getSeasonList = (limit: number, pageNum: number) => {
    return Axios.get(`/seasons/?limit=${limit}&page=${pageNum}`);
}


export const deactivateUser = (username:string) => {
    return Axios.post(`admin/users/by_username/${username}/deactivate`);
}

export const getUserInfo = (username: string) => {
    return Axios.get(`admin/users/by_username/${username}`);
}
const obj = { getBountyList, syncBounties, getGrantList, getGrantInfo, updateGrantState, syncGrant, getReports, requestKYC, getUserList, getRoundList, deactivateUser, getUserInfo };

export default obj;