

export const getKycIcon = (state: string) => {
    switch(state) {
        case 'APPROVED': return '✅';
        case 'DECLINED': return '🛑';
        case 'REQUESTED': return '⏳';
        case 'CANCELLED': return '⚠️';
        case 'PENDING': return '📝';
    }
    return '';
}

export const getGrantUrl = (grant_id: string) => {
    const api_url = process.env.REACT_APP_ACCOUNTS_API_AUDIENCE
    if(api_url?.includes('dev.')) return `https://dev.pomelo.io/grants/${grant_id}`
    return `https://pomelo.io/grants/${grant_id}`
}

export const getUserUrl = (username: string) => {
    const api_url = process.env.REACT_APP_ACCOUNTS_API_AUDIENCE
    if(api_url?.includes('dev.')) return `https://dev.pomelo.io/user/${username}`
    return `https://pomelo.io/user/${username}`
}

export function getTwitterUrl( twitter_handle: string ) {
    if(twitter_handle.startsWith('http:') || twitter_handle.startsWith('https:')) return twitter_handle;
    return "https://twitter.com/" + twitter_handle;
  }

export function getUrl( website: string ) {
    if(website.startsWith('http:') || website.startsWith('https:')) return website;
    return "https://" + website;
}

export function getAccountUrl( account: string ) {
    return `https://bloks.io/account/${account}`;
}

export function timeSince(timestamp: string): string {
    const past = new Date(timestamp);
    const seconds = (new Date().getTime() - past.getTime())/1000;
    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hrs";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sec";
}

export async function timeout( ms: number ): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }