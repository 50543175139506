// @ts-nocheck
import { FC } from "react";
import GrantsTable from '../components/GrantsTable';
import { useRecoilState } from "recoil";
import { isUserState } from "../atoms/atoms";

import { makeStyles, Theme } from '@mui/styles';
import PomeloLogo from '../assets/pomelo.svg';

const useStyles = makeStyles((theme: Theme) => ({
  welcomeDiv:{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },

  welcomeHeading: {
    color: "#021A4D", fontSize: "50px", fontWeight: 300, textAlign: "center", margin: "auto", paddingTop: "96px"
  },

  welcomeImage: {
    height:"400px", textAlign:"center", paddingTop: "50px"
  }
}));

const Home: FC<UserProps> = () => {
  const classes = useStyles();
  const [isUser, setIsUser] = useRecoilState(isUserState);

  return (
    <div className={classes.container} style={{ height:"calc(100vh - 64px)", borderRadius: "25px 25px 0px 0px", backgroundColor: "#FBEFDF" }}>
      {isUser ? <GrantsTable /> : 
      <div className={classes.welcomeDiv}>
        <h1 className={classes.welcomeHeading}>Welcome to Pomelo Admin Portal!</h1>
        <img alt="pomeloLogo" src={PomeloLogo} className={classes.welcomeImage}/>
      </div>
}
    </div>
  );
};

export default Home;
