// src/components/auth-nav.js

import React from "react";
import AuthenticationButton from "./AuthenticationButton";

const AuthNav = () => (
  <div>
    <AuthenticationButton />
  </div>
);

export default AuthNav;
