import { useNavigate } from "react-router-dom";
import React from "react";
import pomeloLogo from '../assets/pomelo.svg'
import { Typography } from "@mui/material";

const MainNav = () => {
  const navigate = useNavigate();
  const handleHomeButtonClick = () => {
    navigate('/')
  }
  // const pomeloTree = 'https://functions.eosn.io/v1/ipfs/QmeFach1u8wQQiggRi8CaBLZk1AZUtEfDiKMAVgmSysSsi?size=700';
  return (<>{
    <div style={{ display: "flex" }}>
      <div>
        <img style={{ width: "70px", height: "50px", cursor: "pointer" }} onClick={handleHomeButtonClick} src={pomeloLogo} alt="home"/>
      </div>
      <div style={{ display: "flex", margin: "auto" }}>
        <Typography variant={'h4'} color="#021B4D" style={{ fontFamily: "proxima-soft,sans-serif" }}>Pomelo</Typography>
        <Typography variant={'h4'} color="#021B4D" style={{ fontFamily: "proxima-soft,sans-serif", paddingTop: "4px" }}>+</Typography>
      </div>
    </div>}</>
  )
};

export default MainNav;
