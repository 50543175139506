import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactHtmlParser from 'react-html-parser';
import DialogContentText from '@mui/material/DialogContentText';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


interface IGrantTranslations {
    translations: any
}

export default function TranslationsPanel({ translations }: IGrantTranslations) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const translationExists = (code: string) => {
        return translations?.find((tr: any) => tr.language?.code === code)?.title.length > 0
    }

    const getTranslation = (code: string) => {
        return translations?.find((tr: any) => tr.language?.code === code)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="grant-language">
                    <Tab key='en' disabled={translationExists('en') ? false : true} label={(translationExists('en')?'✓':'✕') + " English"} {...a11yProps(0)} />
                    <Tab key='zh' disabled={translationExists('zh') ? false : true} label={(translationExists('zh')?'✓':'✕') + " Chinese"} {...a11yProps(1)} />
                    <Tab key='ko' disabled={translationExists('ko') ? false : true} label={(translationExists('ko')?'✓':'✕') + " Korean"} {...a11yProps(2)} />
                </Tabs>
            </Box>
            {['en', 'zh', 'ko'].map((lang, index) => {
                const translation = getTranslation(lang);
                return translationExists(lang) ? <TabPanel value={value} index={index} key={`${lang}-${index}`}>
                    { translation?.title &&
                        <DialogContentText key={`${lang}-${index}-1`}>
                            <h2>{ReactHtmlParser(translation?.title)}</h2>
                            <hr style={{margin: "15px 0px"}} />
                        </DialogContentText>
                    }
                    { translation?.summary &&
                        <DialogContentText key={`${lang}-${index}-2`}>
                            <h4>{ReactHtmlParser(translation?.summary)}</h4>
                            <hr style={{margin: "15px 0px"}} />
                        </DialogContentText>
                    }
                    { translation?.description &&
                        <DialogContentText key={`${lang}-${index}-3`}>
                            {ReactHtmlParser(translation?.description)}
                            <hr style={{margin: "15px 0px"}} />
                        </DialogContentText>
                    }
                    { translation?.rules &&
                        <DialogContentText key={`${lang}-${index}-4`}>
                            {ReactHtmlParser(translation?.rules)}
                            <hr style={{margin: "15px 0px"}} />
                        </DialogContentText>
                    }
                    { translation?.last_season_update &&
                        <DialogContentText key={`${lang}-${index}-5`}>
                            LAST SEASON UPDATE: {ReactHtmlParser(translation?.last_season_update)}
                        </DialogContentText>
                    }
                    </TabPanel> : <></>
            })}
        </Box>
    );
}
