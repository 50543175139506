// @ts-nocheck
import React, { useEffect, useState, FC } from "react";
import { deactivateUser } from "../services/admin";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import {
    Tooltip,
    Button,
    Card,
    Grid,
    IconButton,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { resetKYC, syncUser, syncUsers } from '../services/accounts';
import { getKycIcon, getUserUrl, timeSince } from '../utils/helpers'
import { loadUsers, loadAccounts } from '../utils/apihelpers'
import SyncIcon from '@mui/icons-material/Sync';
import { makeStyles } from '@material-ui/core/styles';

const USERS_PER_PAGE = 50;
const USERS_CHUNK_SIZE = 1000;


const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    }
});


const UsersTable: FC<UserProps> = () => {
    const [userList, setUserList] = useState([])
    const [usersToDisplay, setUsersToDisplay] = useState<any>(userList);
    const [userToDeactivate, setUserToDeactivate] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Username',
            width: 170,
            align: 'left',
            renderCell: (params: any) =>
                params.row.is_deactivated ? (
                    <Tooltip title="User has been deactivated" >
                        <span style={{ color: '#333', fontWeight: 'normal' }}>[deactivated]</span>
                    </Tooltip>
                )
                : (
                    <a style={{ color: '#333', fontWeight: 'bold' }} href={ getUserUrl(params.row.username) } target="_blank" rel="noreferrer">
                        { params.row.username }
                    </a>
                )
        },
        { field: 'name', headerName: 'Name', width: 250, align: 'left', },
        { field: 'email', headerName: 'Email', width: 250, align: 'left', },
        { field: 'eosn_id', headerName: 'EOSN ID', width: 150, align: 'left', },
        {
            field: 'language',
            headerName: 'Language',
            width: 140,
            align: 'center',
            renderCell: (params: any) =>  (
                <div>
                    <span>{ params?.row?.preferred_language?.language ?? "" }</span>
                 </div>
               ),
        },
        { field: 'age', headerName: 'Age', width: 150, align: 'left',
            valueGetter: (params) => params.row.created_at,
            renderCell: (params: any) =>  (
            <div>
                <Tooltip title={params.row?.created_at ?? ''} >
                    <span>{ timeSince(params?.row?.created_at) }</span>
                </Tooltip>
             </div>
            ),
            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime();
            }
        },
        {
            field: 'kyc',
            headerName: 'KYC',
            width: 110,
            align: 'center',
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.kyc ?? ""} >
                        <span>{ getKycIcon(params?.row?.kyc) }</span>
                    </Tooltip>
                 </div>
               ),
        },
        {
            field: 'kyc_required',
            headerName: 'KYC Required?',
            width: 180,
            align: 'center',
            type: 'boolean'
        },
        {
            field: 'kyc_request',
            headerName: 'Send KYC Request',
            width: 200,
            align: 'center',
            renderCell:  (params:string) => {
                if(params.row.is_deactivated){
                    return  <span></span>
                }
                const onClickResetKYC = (e: any) => {
                    e.stopPropagation();
                    let type = 'error'
                    resetKYC(params.row.id)
                    .then((resp: any) => { type = resp.status === 200 ? 'success' : 'error' })
                    .catch(e => { })
                    .finally(() => flashMessage(type === 'success' ? 'KYC has been reset!' : 'KYC reset failed!', type));
                }
                return <Button variant="contained" style={{ backgroundColor: "#18ab6a"}} onClick={onClickResetKYC}>Reset KYC</Button>},
        },
        {
            field: 'deactivate',
            headerName: 'Deactivate',
            width: 150,
            align: 'center',
            renderCell: (params:string) => {
                if(params.row.is_deactivated){
                    return  <span></span>
                }
                const onClickDeactivate = async (e: any) => {
                    e.stopPropagation();
                    setUserToDeactivate(params.row.username);
                }
                return <Button variant="contained" style={{ backgroundColor: "#ff0000"}} onClick={onClickDeactivate}>Deactivate</Button>},
        },
        {
            field: 'sync',
            headerName: 'Sync',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                const onClickSync = (e: any) => {
                    e.stopPropagation();
                    let type = 'error'
                    syncUser(params.row.eosn_id)
                    .then(resp => { type = resp.status === 200 ? 'success' : 'error' })
                    .catch((e) => { })
                    .finally(() => flashMessage(type === 'success' ? 'User sync successful!' : 'User sync failed!', type))
                };

                return <div><IconButton aria-label="sync" onClick={onClickSync}>
                    <SyncIcon />
                </IconButton></div>;
            },
        },
    ];
    useEffect( () => {
        init();
    }, [])

    useEffect(() => {
        setUsersToDisplay(userList);
    }, [userList])

    const init = async () => {
        let chunkStart = 0
        let loadedUsers = [];
        try {
            while(true){
                const users = await loadUsers(chunkStart, USERS_CHUNK_SIZE)
                loadedUsers = loadedUsers.concat(users);
                setUserList(loadedUsers);
                if(users.length !== USERS_CHUNK_SIZE) break;
                chunkStart += USERS_CHUNK_SIZE;
            }
            if(loadedUsers.length === 0) throw new Error("Failed to load users");

            chunkStart = 0;
            while(true){
                const accounts = await loadAccounts(chunkStart, USERS_CHUNK_SIZE)
                accounts.filter(acc => acc.kyc?.state)
                    .forEach(acc => {
                        const user = loadedUsers.find( user => user.eosn_id === acc.eosn_id);
                        if(user) user.kyc = acc.kyc?.state ?? '';
                        // user.lastIp = acc.last_ip ?? '';
                })
                setUserList([...loadedUsers]);
                if(accounts.length !== USERS_CHUNK_SIZE) break;
                chunkStart += USERS_CHUNK_SIZE;
            }
        }
        catch (err) {
            console.log('Users::init', err)
            flashMessage('Failed to load users', "error");
        }
    }

    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }

    const handleConfirmNo= (e: any) => {
        if(userToDeactivate) setUserToDeactivate(null);
    };
    const handleConfirmYes = (e: any) => {
        if(userToDeactivate) {
            handleDeactivateUser(userToDeactivate);
            setUserToDeactivate(null);
        }
    };

    const handleSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        const users = userList.filter( user =>  user.username?.toLowerCase().includes(event.target.value.toLowerCase())
                                            || user.email?.toLowerCase().includes(event.target.value.toLowerCase())
                                            || user.name?.toLowerCase().includes(event.target.value.toLowerCase())
                                            || user.eosn_id?.toLowerCase().includes(event.target.value.toLowerCase())
                                    ) ?? []
        setUsersToDisplay( users )
    }


    const handleDeactivateUser = async (username) => {
        deactivateUser(username)
        .then((resp: any) => {
            if (resp.status !== 200) throw new Error(`Error ${resp.status}`);
            setUserList(userList.map(row => { return { ...row, is_deactivated: row.is_deactivated || row.username === username }}));
            flashMessage("User has been deactivated!", "success");
        })
        .catch((err: any) => {
            flashMessage(`Deactivation failed! ${err?.message ?? err}`, "error");
        })
    }


    const handleSyncUsers = () => {

        syncUsers().then((resp: any) => {
            if (resp.status === 200) flashMessage('Users Sync Successful!', "success");
            else throw new Error(`Error ${resp.status}`);
        }).catch((err: any) => {
            flashMessage(`Users Sync Failed! ${err?.message ?? err}`, "error");
        });
    }


    return  <div style={{ height:"calc(100vh - 64px)" }}>
                <Grid container display="flex" flexDirection="column">
                <Grid item style={{ width: '100%', margin: "auto", padding: "0px 30px 0px 30px" }}>
                    <Typography variant={"h4"} style={{ fontSize: "30px", fontWeight: 400, padding: "24px", color: "#021A4D" }}>Pomelo Users</Typography>
                </Grid>
                <Grid item style={{ padding: "0px 30px 0px 30px" }}>
                    <TextField id="outlined-search-user" label="Search Users" variant="outlined" onChange={handleSearchUser}/>
                </Grid>
                <Card style={{ margin: "20px 30px" }}>
                    <Grid item style={{ cursor: 'pointer', height: 750, width: '100%', margin: "auto" }}>
                        <DataGrid
                            className={classes.root}
                            rows={usersToDisplay}
                            columns={columns}
                            pageSize={USERS_PER_PAGE}
                            rowsPerPageOptions={[USERS_PER_PAGE]}
                            // onRowClick={(e) => handleClickOpen(e)}
                            disableSelectionOnClick
                        />
                    </Grid>
                    <div style={{ textAlign: "center", padding: "20px 0" }}>
                        <Button variant="contained" style={{ backgroundColor: "#18AB6A", borderRadius: "25px", margin: "5px" }} startIcon={<SyncIcon />} onClick={handleSyncUsers}>
                            Sync Users
                        </Button>
                    </div>
                </Card>
            </Grid>
            <Dialog
                open={!!userToDeactivate}
                onClose={handleConfirmNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to deactivate this account?"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmYes}>Yes</Button>
                    <Button onClick={handleConfirmNo} autoFocus>No</Button>
                </DialogActions>
            </Dialog>
        </div>
};

export default UsersTable;
