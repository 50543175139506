import { Dashboard, Report, People, AllInbox, AccessTime, LocalAtm, SavingsOutlined } from '@mui/icons-material';

export const DrawerData: any = [{
    title: "Dashboard",
    icon: <Dashboard />,
    path: "/dashboard",

},
{
    title: "Grants",
    icon: <AllInbox />,
    path: "/grants",

},
{
    title: "Reports",
    icon: <Report />,
    path: "/reports",

},
{
    title: "Users",
    icon: <People />,
    path: "/users",
},
{
    title: "Seasons",
    icon: <AccessTime />,
    path: "/seasons",
},
{
    title: "Rounds",
    icon: <LocalAtm />,
    path: "/rounds",
},
{
    title: "Bounties",
    icon: <SavingsOutlined />,
    path: "/bounties",
},
];
