import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";

// eslint-disable-next-line react-hooks/rules-of-hooks
// const customization = createTheme({ themes });

ReactDOM.render(
  <RecoilRoot>
    {/* <ThemeProvider theme={customization}> */}
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <SnackbarProvider
          maxSnack={5}
          sx={{
            '& .SnackbarItem-variantSuccess': {
              backgroundColor: '#18AB6B', //your custom color here
            },
            '& .SnackbarItem-variantError': {
              //backgroundColor: 'red', //your custom color here
            },
          }}
          style={{
            marginBottom: "20px",
            borderRadius: "25px 5px 5px 25px",
          }}
        >
          <App />
        </SnackbarProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
    {/* </ThemeProvider> */}
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
