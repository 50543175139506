import MainNav from "./MainNav";
import AuthNav from "./AuthNav";
import { AppBar } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';

const NavBar = () => {
  return (
    <AppBar position="static"
      style={{
        // backgroundImage: "linear-gradient(to right, #d35e30, #641fe97a)"
        // backgroundColor: "#163A8000",
        backgroundColor: "#FFF",
        // position: "absolute",
      }}
      elevation={0}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <MainNav />

        <AuthNav />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;