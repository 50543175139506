import { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Tooltip, Typography, Card, Grid  } from '@mui/material';
import { useRecoilState } from 'recoil';
import { ReportListState } from '../atoms/atoms';
import { useSnackbar } from 'notistack';
import { getGrantUrl, getUserUrl } from '../utils/helpers'
import { loadAllAbuseReports } from '../utils/apihelpers'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    }
});

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
        valueGetter: (params) => params.row.id
    },
    {
        field: 'name',
        headerName: 'Grant',
        width: 140,
        renderCell: (params: any) =>  (
            <a style={{ textDecoration: 'none', color: '#333', fontWeight: 'bold' }} href={ getGrantUrl(params.row.reported_grant.name) } target="_blank" rel="noreferrer" >
                { params.row.reported_grant.name }
             </a>
           )
    },
    {
        field: 'title',
        headerName: 'Grant Title',
        width: 250,
        editable: false,
        valueGetter: (params) => params.row.reported_grant.title
    },
    {
        field: 'reason',
        headerName: 'Reason',
        // type: 'number',
        width: 400,
        renderCell: (params: any) =>  (
            <div>
                <Tooltip title={params.row?.reason} >
                    <span>{ params?.row?.reason }</span>
                </Tooltip>
             </div>
           ),
    },
    {
        field: 'isHandled',
        headerName: 'Handled?',
        type: 'boolean',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.isHandled
        // `${params.getValue(params.id, 'owner') || ''}`
    },
    {
        field: 'reporting_user',
        headerName: 'Reporting User',
        width: 300,
        editable: true,
        renderCell: (params: any) =>  (
            <div>
                <a style={{ textDecoration: 'none', color: '#333', fontWeight: 'bold' }} href={ getUserUrl(params.row.reporting_user.username) } target="_blank" rel="noreferrer">
                    { `${params.row.reporting_user.username}` }
                </a>
                <span> ({ params.row.reporting_user.email })</span>
            </div>
           )
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: 'number',
        width: 200,
        editable: true,
    },
];


export default function ReportsTable() {
    const [reportList, setReportList] = useRecoilState(ReportListState);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        run();
    }, []);

    const run = async () => {
        try {
            const reports = await loadAllAbuseReports();
            setReportList(reports);
        }
        catch(err) {
            enqueueSnackbar('Failed to load reports', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error'
            });
        }
    }

    return (reportList.length > 0 ?
        <div style={{ height:"calc(100vh - 64px)", borderRadius: "25px 25px 0px 0px", backgroundColor: "#FBEFDF" }}>
            <Grid container display="flex" flexDirection="column">
                <Grid item style={{ width: '100%', margin: "auto", padding: "0px 30px 0px 30px" }}>
                    <Typography variant={"h4"} style={{ fontSize: "30px", fontWeight: 400, padding: "24px", color: "#021A4D" }}>Pomelo Reports</Typography>
                </Grid>
                <Card style={{ margin: "0px 30px" }}>
                    <Grid item style={{ cursor: 'pointer', height: 750, width: '100%', margin: "auto" }}>
                        <DataGrid
                            className={classes.root}
                            rows={reportList}
                            columns={columns}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            disableSelectionOnClick
                        />
                    </Grid>
                    <div style={{ textAlign: "center", padding: "20px 0" }}>
                    </div>
                </Card>

            </Grid>
        </div> : <></>
    );
}