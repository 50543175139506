import { getKycStates, getAccounts } from '../services/accounts'
import { getReports, getGrantList, getUserList, getRoundList, getSeasonList } from '../services/admin'

export const loadAllAbuseReports = async () => {
    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstReportListResponse: any = await getReports(limit, initialPageNum)

    totalPages = firstReportListResponse.data.meta?.total_pages ?? 1;

    const reportListPromises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getReports(limit, pageNumber + 1)
    })

    const reportListResponse: any = await Promise.all(reportListPromises)

    const reports: any = reportListResponse.reduce((prevValue: any, currentValue: any) => {
        return [...prevValue, ...currentValue.data.data]
    }, firstReportListResponse.data.data)

    return reports
}


export const loadAllKycStates = async () => {
    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstResponse: any = await getKycStates(limit, initialPageNum)
    totalPages = firstResponse.data.meta?.total_pages ?? 1;
    const promises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getKycStates(limit, pageNumber + 1)
    })

    const responses: any = await Promise.all(promises)

    const res: any = responses.reduce((prevValue: any, currentValue: any) => {
        return [...prevValue, ...currentValue.data.data]
    }, firstResponse.data.data)

    return res
}


export const loadAccounts = async (chunkStart: number, chunkSize: number) => {

    const limit = 50;
    const firstPage = Math.floor((chunkStart / limit)) + 1;

    //first page of the chunk
    const firstResponse: any = await getAccounts(limit, firstPage).catch(null);  //ignore error
    const totalPages = firstResponse.data?.meta?.total_pages ?? 1;
    const lastPage = Math.min(totalPages, Math.floor((chunkStart + chunkSize) / limit) + ((chunkStart + chunkSize) % limit ? 1 : 0))

    // console.log('🫐', {firstPage, lastPage, totalPages, chunkStart, chunkSize})
    // console.log('🦞', Array.from(Array(lastPage - firstPage + 1).keys()).slice(1))
    const promises: any = Array.from(Array(lastPage - firstPage + 1).keys()).slice(1).map((pageNumber: any) => {
        return getAccounts(limit, firstPage + pageNumber)
    })

    const responses: any = await Promise.all(promises)

    const accounts: any = responses.reduce((acc: any, cur: any) => ([...acc, ...cur.data.data]), firstResponse.data.data);
console.log('🐍', accounts)
    return accounts;
}

export const loadAllGrants = async () => {
    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstGrantListResponse = await getGrantList(limit, initialPageNum);

    totalPages = firstGrantListResponse.data.meta?.total_pages ?? 1;

    const grantListPromises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getGrantList(limit, pageNumber + 1)
    })

    const grantListResponse: any = await Promise.all(grantListPromises)

    const grants: Object[] = grantListResponse.reduce((prevValue: any, currentValue: any) => {
        return [...prevValue, ...currentValue.data.data]
    }, firstGrantListResponse.data.data)

    return grants
}

export const loadAllUsers = async () => {

    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstUserListResponse: any = await getUserList(limit, initialPageNum)

    totalPages = firstUserListResponse.data.meta?.total_pages ?? 1;

    const userListPromises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getUserList(limit, pageNumber + 1)
    })

    const userListResponse: any = await Promise.all(userListPromises)

    const users: any = userListResponse.reduce((prevValue: any, currentValue: any) => {
        currentValue.id = currentValue.eosn_id;
        return [...prevValue, ...currentValue.data.data]
    }, firstUserListResponse.data.data);

    const reducedUsers: any = users.reduce((prevValue: any, user: any) => {
        user.id = user.eosn_id
        return [...prevValue, user]
    }, [])

    return reducedUsers

}


export const loadUsers = async (chunkStart: number, chunkSize: number) => {

    const limit = 50;
    const firstPage = Math.floor((chunkStart / limit)) + 1;

    //first page of the chunk
    const firstResponse: any = await getUserList(limit, firstPage).catch(null);  //ignore error
    const totalPages = firstResponse.data?.meta?.total_pages ?? 1;
    const lastPage = Math.min(totalPages, Math.floor((chunkStart + chunkSize) / limit) + ((chunkStart + chunkSize) % limit ? 1 : 0))

    // console.log('🌶', {firstPage, lastPage, totalPages, chunkStart, chunkSize})
    const promises: any = Array.from(Array(lastPage - firstPage + 1).keys()).slice(1).map((pageNumber: any) => {
        return getUserList(limit, firstPage + pageNumber)
    })

    const responses: any = await Promise.all(promises)

    const users: any = responses.reduce((acc: any, cur: any) => ([...acc, ...cur.data.data]), firstResponse.data.data);

    return users.reduce((acc: any, cur: any) => ([...acc, {...cur, id: cur.eosn_id}]), []);
}

export const loadAllRounds = async () => {
    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstResponse: any = await getRoundList(limit, initialPageNum);

    totalPages = firstResponse.data.meta?.total_pages ?? 1;

    const promises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getRoundList(limit, pageNumber + 1)
    })

    const responses: any = await Promise.all(promises)

    const rounds: Object[] = responses.reduce((prevValue: any, currentValue: any) => {
        return [...prevValue, ...currentValue.data.data]
    }, firstResponse.data.data)

    return rounds
}


export const loadAllSeasons = async () => {
    let totalPages = 0;
    let limit = 50;
    let initialPageNum = 1;

    const firstResponse: any = await getSeasonList(limit, initialPageNum);

    totalPages = firstResponse.data.meta?.total_pages ?? 1;

    const promises: any = Array.from(Array(totalPages).keys()).slice(1).map((pageNumber: any) => {
        return getRoundList(limit, pageNumber + 1)
    })

    const responses: any = await Promise.all(promises)

    const seasons: Object[] = responses.reduce((prevValue: any, currentValue: any) => {
        return [...prevValue, ...currentValue.data.data]
    }, firstResponse.data.data)

    return seasons
}
