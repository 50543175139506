import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TranslationsPanel from './TranslationsPanel';
import { styled } from '@mui/system';
import { MenuItem, Select, TextField, Typography } from '@mui/material';
import { getGrantInfo, getUserInfo, updateGrantState, updateRoundState } from '../services/admin';
import { useRecoilState } from 'recoil';
import { grantDialogOpenState } from '../atoms/atoms';
import { VariantType, useSnackbar } from 'notistack';
import { getUserUrl, getUrl, getGrantUrl, getTwitterUrl, timeSince } from '../utils/helpers';
import {IGrantList} from '../utils/interfaces';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// MuiDialog-paper
// const CustomButtonRoot = styled('Dialog')(`

// `)

const DialogPaper = styled(Dialog)({
    "& .MuiDialog-paper": {
        maxWidth: "100%"
    }
});


const GridDialogContent = styled(DialogContent)({
    borderRadius: '24px',
    display: 'grid',
    width: '90vw',
    maxWidth: '1200px',
    gridTemplateColumns: `5fr 4fr`,
    gridTemplateRows: 'auto',
    gridGap: '10px',
    gridTemplateAreas: `
        "logo info"
        "trans trans"
        "reports reports"`,
    '@media(max-width: 800px)': {
        gridTemplateColumns: `1fr`,
        gridTemplateAreas: `
            "logo"
            "info"
            "trans"
            "reports"`
    }
})


const LogoDiv = styled('div')({
    borderRadius: '24px',
    gridArea: 'logo',
    textAlign: 'center'
})

const InfoDiv = styled('div')({
    padding: "0px 24px 24px 24px",
    backgroundColor: "#f3f2f2",
    borderRadius: "24px",
    gridArea: 'info'
})

const InfoText = styled(Typography)({
    paddingTop: "16px"
})

const TranslationsDiv = styled('div')({
    padding: "0px 24px 24px 24px",
    backgroundColor: "#f3f2f2",
    borderRadius: "24px",
    gridArea: 'trans'
})

const ReportsDiv = styled('div')({
    padding: "0px 24px 24px 24px",
    backgroundColor: "#f3f2f2",
    borderRadius: "24px",
    gridArea: 'reports'
})

const LogoImg = styled('img')({
    maxWidth:'100%',
    maxHeight:'100%',
    borderRadius: '24px',
})

interface IGrantDialog {
    // open: boolean,
    grant: IGrantList,
    grantList: IGrantList[],
    season: {
        matching_rounds: any[]
    },
}
const GrantDialog = ({ grant, grantList, season }: IGrantDialog) => {
    // const [dialogOpen, setDialogOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [grantDialogOpen, setGrantDialogOpen] = useRecoilState(grantDialogOpenState);
    const { enqueueSnackbar } = useSnackbar();
    const [errorText, setErrorText] = useState("");
    const [matchingRound, setMatchingRound] = useState("");

    const [grantDetails, setGrantDetails] = useState<any>(null);
    const [ownerInfo, setOwnerInfo] = useState<any>(null);

    const handleClose = () => {
        setErrorText("")
        setGrantDialogOpen(false);
    };

    const handleMatchingRoundChange = (event: any) => {
        setMatchingRound(event.target.value);
    };


    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }


    const getGrantDetails = async (grant: any) => {
        if(!grant) return;
        const resp = await getGrantInfo(grant.name);
        setGrantDetails(resp.data.data)
        const ownerUsername = resp.data.data?.owner?.username;
        if (ownerUsername) {
            getUserInfo(ownerUsername).then((resp: any) => {
                setOwnerInfo(resp.data.data);
                console.log('getUserInfo data: ', resp.data.data)
            })
        }
        console.log('🦐 grantDetails data: ', resp.data.data)
    }

    const updateGrantStatus = (grantState: any) => {
        const stateReason = reason.trim() === "" ? `State changed to ${grantState} with no reason provided` : reason;
        updateGrantState(grant.name, grantState, stateReason).then((resp: any) => {
            setReason("");
            flashMessage("Grant state successfully updated");
        }).catch((err: any) => {
            flashMessage(`Failed to update grant state: ${err}`, "error");
        });
    }

    const updateRoundStatus = (roundState: any) => {
        if(!matchingRound) return;
        const force = grant.matching_round?.matching_round_id !== +matchingRound;
        const stateReason = reason.trim() === "" ? `Round state changed to ${roundState} with no reason provided` : reason;
        updateRoundState(grant.name, +matchingRound, roundState, stateReason, force).then((resp: any) => {
            handleClose();
            flashMessage("Grant round state successfully updated");
        }).catch((err: any) => {
            console.log('error: ', err)
            flashMessage(`Failed to update grant round state: ${err}`, "error");
        });
    }

    useEffect(() => {
        if(!grantDialogOpen) return;
        setGrantDetails(null);
        setMatchingRound(`${grant.matching_round?.matching_round_id ?? ''}`)
        getGrantDetails(grant);
    }, [grantDialogOpen, grant])

    return (
        grantDetails !== null ? <div>
            <DialogPaper
                open={grantDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                style={{ borderRadius: "24px" }}
                sx={{
                    '& .status': {
                        color: "white",
                        borderRadius: "15px",
                        padding: "5px 15px",
                        lineHeight: "24px",
                        marginLeft: "5px",
                        marginRight: "10px",
                    },
                    '& .PUBLISHED': {
                        background: "#18ab6b",
                    },
                    '& .APPROVED': {
                        background: "#18ab6b",
                    },
                    '& .BANNED': {
                        background: "#000000",
                    },
                    '& .DENIED': {
                        background: "#FF595E",
                    },
                    '& .PENDING': {
                        background: "#fcaf4a",
                    },
                    '& .ACTION_REQUIRED': {
                        background: "#fc6e4a",
                    },
                }}
            >
                <DialogTitle>{grantDetails.title}</DialogTitle>
                <GridDialogContent >
                    <LogoDiv>
                        <LogoImg src={grantDetails.project_logo_url ?? 'https://dev.pomelo.io/_nuxt/img/grant-default-logo.7f2bbd0.svg'} />
                    </LogoDiv>
                    <InfoDiv>
                        <InfoText ><b>Grant ID:</b> <a target="_blank" rel="noreferrer" href={getGrantUrl(grant.name)}>{grant.name}</a></InfoText>
                        <InfoText ><b>Author:</b> <a target="_blank" rel="noreferrer" href={getUserUrl(grant.owner.username)}>{grant.owner.username}</a> - {ownerInfo?.preferred_language?.language ?? ""}</InfoText>
                        <InfoText ><b>Funding Account:</b> <a target="_blank" rel="noreferrer" href={'https://bloks.io/account/'+grantDetails.funding_address}>{grantDetails.funding_address}</a></InfoText>
                        <InfoText ><b>State:</b> <span className={"status " + grantDetails.state}>{grantDetails.state}</span></InfoText>
                        {
                            (grantDetails.matching_rounds ?? [])
                                .filter((grantRound: any) => season.matching_rounds?.map((r: any) => r.matching_round_id).includes(grantRound.matching_round_id))
                                .map((round: any, i: number) => (
                                <InfoText >
                                    <b>Round:</b>
                                    <span className={"status " + round.state}>{round.state ?? ''}</span>
                                    <span >{round?.title ?? ''}{grant.matching_round?.matching_round_id === round.matching_round_id ? " - ACTIVE" : ""}</span>
                                </InfoText>
                                )
                            )
                        }
                        <InfoText ><b>Category:</b> <span >{grantDetails.category.name}</span></InfoText>
                        <InfoText ><b>Region:</b> <span >{grantDetails.region.name}</span></InfoText>
                        <InfoText ><b>Accepts:</b> <span >{grantDetails.accepted_tokens.map((tok: any) => tok.symbol).join(', ')}</span></InfoText>
                        <InfoText ><b>Raised:</b> {grantDetails.total_contribution_stats?.amount_raised ?? 0} {grantDetails.total_contribution_stats?.currency.symbol ?? ''} from {grantDetails.total_contribution_stats?.contributors ?? 0} contributors</InfoText>
                        <InfoText ><b>Seasons:</b> <span >{grantDetails.matching_rounds?.length ?? 0}</span></InfoText>
                        { grantDetails.website_url ? <InfoText ><b>Website:</b> <a target="_blank" rel="noreferrer" href={getUrl(grantDetails.website_url)}>{grantDetails.website_url}</a></InfoText> : <></> }
                        { grantDetails.twitter_handle ? <InfoText ><b>Twitter:</b> <a target="_blank" rel="noreferrer" href={getTwitterUrl(grantDetails.twitter_handle)}>{grantDetails.twitter_handle}</a></InfoText> : <></> }
                        { grantDetails.github_project_url ? <InfoText ><b>Github:</b> <a target="_blank" rel="noreferrer" href={getUrl(grantDetails.github_project_url)}>{grantDetails.github_project_url}</a></InfoText> : <></> }
                    </InfoDiv>
                    <TranslationsDiv>
                        <TranslationsPanel translations={grantDetails.grant_translations} />
                    </TranslationsDiv>
                    <ReportsDiv>
                        <InfoText ><b>Abuse Reports:</b></InfoText>
                        {
                            grant.reports.map((report: any, i: number) =>
                                <InfoText key={`${grant.name}-report#${i}`}><b>{report.reporting_user.name}</b> <i>({report.reporting_user.email})</i>: {report.reason} - <i>{timeSince(report.created_at)} ago</i></InfoText>)
                        }
                    </ReportsDiv>

                </GridDialogContent>
                <DialogActions style={{ padding: "24px" }}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Reason"
                        multiline
                        rows={3}
                        fullWidth
                        value={reason}
                        error={errorText !== ""}
                        helperText={errorText}
                        onChange={(e: any) => { setErrorText(""); setReason(() => e.target.value)}}
                    />
                </DialogActions>
                <DialogActions>
                    <Typography style={{ paddingRight: "24px" }}><b>Grant Status:</b></Typography>
                    {
                        ["PUBLISHED", "PENDING", "DENIED", "BANNED", "RETIRED"].map((status: string) => <Button key={status} onClick={(e) => updateGrantStatus(status)}>{status}</Button>)
                    }
                </DialogActions>
                <DialogActions>
                    <Typography style={{ paddingRight: "24px" }}>
                        <Select
                            id="matchingRound"
                            value={matchingRound}
                            onChange={handleMatchingRoundChange}
                            autoWidth
                            style={{ marginRight: "16px", minWidth: "200px", height: "40px"}}
                        >
                            <MenuItem value="">
                                <em>Select matching round...</em>
                            </MenuItem>
                            {
                                season?.matching_rounds?.map((round: any) => <MenuItem key={round.matching_round_id} value={round.matching_round_id}>{round.title}</MenuItem>)
                            }
                        </Select>
                        <b>Round Status:</b></Typography>
                        {
                            ["APPROVED", "PENDING", "ACTION_REQUIRED", "DENIED", "BANNED"]
                                .map((status: string) => <Button disabled={!matchingRound} key={status} onClick={(e) => updateRoundStatus(status)}>{status}</Button>)
                        }
                </DialogActions>
            </DialogPaper>
        </div> : <></>
    );
}


export default GrantDialog;