import React, { useEffect } from "react";
import "./App.css";
import PageRoutes from "./pageRoutes";

function App() {

  useEffect(() => {

  }, []);


  return (
    <div>
      <PageRoutes />
    </div>
  );
}

export default App;

