import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Tooltip, Card, Grid, TextField, Button, Typography, Modal, Checkbox } from '@mui/material';
import RoundDialog from './RoundDialog';
import { useRecoilState } from 'recoil';
import { roundDialogOpenState, RoundListState } from '../atoms/atoms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { VariantType,useSnackbar } from 'notistack';
import * as pomelo from "pomelo-sdk";
import { loadAllRounds } from '../utils/apihelpers';
import {RoundInfo} from '../utils/interfaces';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)({
    "& .MuiDataGrid-cell:focus":
    {
        outline: "none",
    },
});

const ROUNDS_PER_PAGE = 50;

export default function RoundsTable() {
    const [roundDialogOpen, setRoundDialogOpen] = useRecoilState(roundDialogOpenState);
    const [selectedRound, setSelectedRound] = useState<any>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [roundList, setRoundList] = useRecoilState(RoundListState);
    const [roundsToDisplay, setRoundsToDisplay] = useState<Array<RoundInfo>>(roundList);
    const [searchRound, setSearchRound] = useState<any>('');
    const [isActiveChecked, setIsActiveChecked] = React.useState(false);

    const columns: GridColDef[] = [
        {
            field: 'round_id',
            headerName: '#',
            width: 100,
            renderCell: (params: any) =>  (
                <span>
                    { params.row.matching_round_id }
                </span>
            )
        },
        {
            field: 'title',
            headerName: 'Round Title',
            width: 250,
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.title ?? ""} >
                        <span>{ params?.row?.title }</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 220,
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.description ?? ""} >
                        <span>{ params.row?.description }</span>
                    </Tooltip>
                </div>
            )
        },
        {
            field: 'season_id',
            headerName: 'Season',
            width: 140,
            renderCell: (params: any) =>  (
                <span>
                    { params.row.season_id }
                </span>
            )
        },
        {
            field: 'match_value',
            headerName: 'Value',
            width: 180,
            renderCell: (params: any) =>  (
                <span >
                    ${ params.row.amount_usd }
                </span>
            )
        },
        {
            field: 'approved_tokens',
            headerName: 'Tokens',
            width: 180,
            renderCell: (params: any) =>  (
                <span >
                    { params.row.approved_tokens?.map((token: any) => token.symbol).join(', ') ?? "" }
                </span>
            )
        },
        {
            field: 'submissions_start_at',
            headerName: 'Submissions Start',
            width: 200,
        },
        {
            field: 'submissions_end_at',
            headerName: 'Submissions End',
            width: 200,
        },
        {
            field: 'start_at',
            headerName: 'Start',
            width: 200,
        },
        {
            field: 'end_at',
            headerName: 'End',
            width: 200,
        },
        {
            field: 'finalized_at',
            headerName: 'Finalized',
            width: 200,
        },
    ];

    useEffect(() => {
        console.log('roundDialogOpen changed', roundDialogOpen)
        if (!roundDialogOpen) {
            init();
        }
    }, [roundDialogOpen])

    useEffect(() => {
        filterRounds(searchRound, isActiveChecked)
    }, [roundList])


    const handleClickOpen = (e: any) => {
        console.log(e.row);
        setSelectedRound(e.row);
        setRoundDialogOpen(true);
    };

    const handleSearchRound = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchRound(event.target.value)
        filterRounds(event.target.value, isActiveChecked)
    }

    const handleActiveChange = (event: any) => {
        setIsActiveChecked(event.target.checked);
        filterRounds(searchRound, event.target.checked)
    };

    const filterRounds = (searchToken: string, activeOnly: boolean) => {
        const token = searchToken.toLowerCase()
        const rounds = roundList.filter((round: any) =>
            ((`${round.matching_round_id}`).includes(token) ||
            round.title?.toLowerCase().includes(token) ||
            round.rules?.toLowerCase().includes(token) ||
            round.approved_tokens.map((token: any) => token.symbol).join(', ')?.toLowerCase()?.includes(token) ||
            round.description?.toLowerCase().includes(token))
            && (!activeOnly || !round.finalized_at)
        ) ?? []
        setRoundsToDisplay( rounds )
    }

    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }

    const handleCreateRound = () => {

        flashMessage(`TODO!`, "error");
    }

    const init = async () => {

        try {
            const [ rounds, chainRounds ] = await Promise.all([
                loadAllRounds(),
                pomelo.get_rounds()
            ].map(p => p.catch(e => null)));
            console.log('rounds', { rounds, chainRounds })
            if(!chainRounds) flashMessage('Failed to load chain rounds', "warning");
            if(rounds === null) flashMessage('Failed to load rounds', "warning");

            const reducedRounds: any = (rounds ?? []).reduce((prevValue: any, round: any) => {
                round.id = round.matching_round_id;
                return [...prevValue, round]
            }, [])

            console.log('reducedRounds', { reducedRounds: reducedRounds })
            setRoundList(reducedRounds)
        }
        catch (err) {
            flashMessage('Failed to load rounds', "error");
        }
    }

    return <div style={{ height:"calc(100vh - 64px)" }}>
            <RoundDialog round={selectedRound} />
            <Grid container display="flex" flexDirection="column">
                <Grid item style={{ width: '100%', margin: "auto", padding: "0px 30px 0px 30px" }}>
                    <Typography variant={"h4"} style={{ fontSize: "30px", fontWeight: 400, padding: "24px", color: "#021A4D" }}>Pomelo Rounds</Typography>
                    <Grid container>
                        <Grid item style={{ paddingLeft: "16px" }}>
                            <TextField id="outlined-search-season-id" label="Search Rounds" variant="outlined" value={searchRound} onChange={handleSearchRound}/>
                        </Grid>
                        <Grid item style={{ paddingLeft: "16px", display: 'flex', alignItems: 'center' }}>
                            <Checkbox checked={isActiveChecked} onChange={handleActiveChange} color="primary" />
                            <Typography variant="body1">Active Only</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Card style={{ margin: "20px 30px" }} >
                    <Grid item style={{ cursor: 'pointer', height: 750, width: '100%', margin: "auto" }}>
                        <StyledDataGrid
                            rows={roundsToDisplay}
                            columns={columns}
                            pageSize={ROUNDS_PER_PAGE}
                            rowsPerPageOptions={[ROUNDS_PER_PAGE]}
                            onRowClick={(e) => handleClickOpen(e)}
                            disableSelectionOnClick
                            componentsProps={{ panel: {as: Modal}}}
                        />
                    </Grid>
                    <div style={{ textAlign: "center", padding: "20px 0" }}>
                        <Button variant="contained" style={{ backgroundColor: "#18AB6A", borderRadius: "25px", margin: "5px" }} startIcon={<AddCircleIcon />} onClick={handleCreateRound}>
                            Create Round
                        </Button>
                    </div>
                </Card>

            </Grid>
        </div>
}