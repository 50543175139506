import axios from "axios";

const audience =
  process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_AUTH0_AUDIENCE + "/v1/"
    : process.env.REACT_APP_AUTH0_AUDIENCE;

const Axios = axios.create({
  baseURL: audience,
});

Axios.interceptors.request.use(
  async (request) => {
    const accessToken = localStorage.getItem("authSession") || null;

    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  },
  (error) => {
    return Promise.reject(new Error({ ...error }));
  }
);

export default Axios;
