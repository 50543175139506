import axios from "axios";

// Accounts API

const audience = process.env.REACT_APP_ACCOUNTS_API_AUDIENCE;

const Axios = axios.create({
    baseURL: audience,
});

Axios.interceptors.request.use(
    async (request: any) => {
        const accessToken = localStorage.getItem("authSession") || null;
        request.headers.Authorization = `Bearer ${accessToken}`;
        return request;
    },
    (error) => {
        return Promise.reject(new Error({ ...error }));
    }
);

export const getKycStates = (limit: number, pageNum: number): any => {
    return Axios.get(`/admin/users?limit=${limit}&page=${pageNum}&kyc_status[]=REQUESTED&kyc_status[]=APPROVED&kyc_status[]=DECLINED&kyc_status[]=CANCELLED&kyc_status[]=PENDING`);
}

export const getAccounts = (limit: number, pageNum: number): any => {
    return Axios.get(`/admin/users?limit=${limit}&page=${pageNum}`);
}

export const syncUsers = () => {
    return Axios.post(`admin/users/sync`);
}

export const syncUser = (userName: string): any => {
    return Axios.post(`admin/users/by_id/${userName}/sync`);

}

export const resetKYC = (eosnID:string) => {
    return Axios.post(`admin/users/by_id/${eosnID}/reset_kyc`);
}

const obj = { syncUsers, resetKYC, syncUser, getKycStates };
export default obj;