import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridFilterItem, GridFilterInputValueProps } from '@mui/x-data-grid';
import { Tooltip, Card, Grid, MenuItem, TextField, Button, IconButton, Typography, Modal, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import GrantDialog from './GrantDialog';
import { syncGrant, requestKYC, syncGrants } from '../services/admin';
import { useRecoilState } from 'recoil';
import { grantDialogOpenState, GrantListState } from '../atoms/atoms';
import SyncIcon from '@mui/icons-material/Sync';
import { VariantType,useSnackbar } from 'notistack';
import * as pomelo from "pomelo-sdk";
import { getKycIcon, getGrantUrl, getUserUrl, getAccountUrl } from '../utils/helpers';
import { loadAllGrants, loadAllKycStates, loadAllAbuseReports, loadAllSeasons } from '../utils/apihelpers';
import {IGrantList} from '../utils/interfaces';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)({
    "& .MuiDataGrid-cell:focus":
    {
        outline: "none",
    },
});

const GRANTS_PER_PAGE = 50;

const GrantStateDropdown = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const grantStates = [
        {
            value: 'PENDING',
            label: 'PENDING',
        },
        {
            value: 'PUBLISHED',
            label: 'PUBLISHED',
        },
        {
            value: 'RETIRED',
            label: 'RETIRED',
        },
        {
            value: 'BANNED',
            label: 'BANNED',
        },
        {
            value: 'DENIED',
            label: 'DENIED',
        },
    ];
    const [grantState, setGrantState] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log({item})
        setGrantState(event.target.value);
        applyValue({ ...item, value: event.target.value });
    };

    return <TextField
                id="select-grant-state"
                select
                label="Grant State"
                value={grantState}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
            >
          {grantStates.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
}

const RoundStateDropdown = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const roundStates = [
        {
            value: 'APPROVED',
            label: 'APPROVED',
        },
        {
            value: 'DENIED',
            label: 'DENIED',
        },
        {
            value: 'PENDING',
            label: 'PENDING',
        },
        {
            value: 'ACTION_REQUIRED',
            label: 'ACTION_REQUIRED',
        },
    ];
    const [roundState, setRoundState] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log({item})
        setRoundState(event.target.value);
        applyValue({ ...item, value: event.target.value });
    };

    return <TextField
                id="select-round-state"
                select
                label="Round State"
                value={roundState}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
            >
          {roundStates.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
}


// const columns1 = React.useMemo(
//     () =>
//       data.columns.map((col:any) =>
//         col.field === 'rating'
//           ? {
//               ...col,
//               filterOperators: ratingOnlyOperators,
//             }
//           : col,
//       ),
//     [data.columns],
//   );



export default function GrantsTable() {
    const [grantDialogOpen, setGrantDialogOpen] = useRecoilState(grantDialogOpenState);
    const [grantList, setGrantList] = useRecoilState(GrantListState);
    const [grantsToDisplay, setGrantsToDisplay] = useState<Array<IGrantList>>(grantList);
    const [selectedGrant, setSelectedGrant] = useState<any>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [seasonList, setSeasonList] = useState<any>([]);
    const [selectedSeason, setSelectedSeason] = useState<any>(null);
    const [searchGrant, setSearchGrant] = useState<any>('');
    const [isAppliedChecked, setIsAppliedChecked] = React.useState(false);



    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Grant ID',
            width: 160,
            renderCell: (params: any) =>  (
                <a style={{ color: '#333', fontWeight: 'bold' }} href={ getGrantUrl(params.row.name) } rel="noreferrer" target="_blank" >
                    { params.row.name }
                </a>
            )
        },
        {
            field: 'title',
            headerName: 'Grant Title',
            width: 250,
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.title} >
                        <span>{ params?.row?.title }</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'owner',
            headerName: 'Owner',
            width: 180,
            renderCell: (params: any) =>  (
                <a style={{ color: '#333', fontWeight: 'bold' }} href={ getUserUrl(params.row.owner?.username) } rel="noreferrer" target="_blank" >
                    { params.row.owner?.username }
                </a>
            )
        },
        {
            field: 'funding_address',
            headerName: 'Funding Address',
            width: 180,
            renderCell: (params: any) =>  (
                <a style={{ color: '#333', fontWeight: 'bold' }} href={ getAccountUrl(params.row.funding_address) } rel="noreferrer" target="_blank" >
                    { params.row.funding_address }
                </a>
            )
        },
        {
            field: 'state',
            headerName: 'Grant State',
            width: 170,
            renderCell: (params: any) =>  (
                <span className={"status " + params.row.state} >
                    { params.row.state }
                </span>
            ),
            filterOperators: [{
                    label: 'is',
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        console.log({filterItem})
                        if (
                            !filterItem.columnField ||
                            !filterItem.value ||
                            !filterItem.operatorValue
                        ) {
                            return null;
                        }
                        return (params): boolean => {
                            return String(params.value) === String(filterItem.value);
                        };
                    },
                    InputComponent: GrantStateDropdown,
                    InputComponentProps: { type: 'string' },
                }],
        },
        {
            field: 'round',
            headerName: 'Round',
            width: 170,
            renderCell: (params: any) =>  {
                const round = params.row.matching_round;
                return (
                    <span>
                        { round ? `#${round.matching_round_id} - ${round.title}` : "" }
                    </span>
                )
            }
        },
        {
            field: 'roundState',
            headerName: 'Round State',
            width: 170,
            renderCell: (params: any) =>  (
                params.row.roundState
                ? <span className={"status " + params.row.roundState} >
                    { params.row.roundState }
                </span>
                : <></>
            ),
            filterOperators: [{
                label: 'is',
                value: 'is',
                getApplyFilterFn: (filterItem: GridFilterItem) => {
                    console.log({filterItem})
                    if (
                        !filterItem.columnField ||
                        !filterItem.value ||
                        !filterItem.operatorValue
                    ) {
                        return null;
                    }
                    return (params): boolean => {
                        return String(params.value) === String(filterItem.value);
                    };
                },
                InputComponent: RoundStateDropdown,
                InputComponentProps: { type: 'string' },
            }],
        },
        {
            field: 'chainState',
            headerName: 'Chain State',
            width: 150,
            valueGetter: (params: any) =>  {
                const chainState =  (params.row?.chainState ?? '').toUpperCase()
                const isValid = chainState === params.row.state;
                return `${chainState} ${ isValid ? '' : '⚠️'}`
            }
        },
        {
            field: 'chainJoined',
            headerName: 'Chain Joined',
            width: 150,
            valueGetter: (params: any) =>  {
                const chainRounds = params.row?.chainRounds?.join(',') ?? '';
                const isValid =(params.row.roundState !== 'APPROVED' && chainRounds === "") || (params.row.roundState === "APPROVED" && +chainRounds === params.row.matching_round.matching_round_id);
                return `${chainRounds} ${ isValid ? '' : '⚠️'}`
            }
        },
        {
            field: 'kyc',
            headerName: 'KYC',
            width: 90,
            disableColumnMenu: true,
            align: 'center',
            renderCell: (params: any) =>  (
                <div>
                    <Tooltip title={params.row?.kyc} >
                        <span>{ getKycIcon(params?.row?.kyc) }</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'contributions',
            headerName: 'Contributions',
            width: 180,
            valueGetter: (params) => `${params.row.total_contribution_stats?.amount_raised ?? '0'} (${params.row.total_contribution_stats?.contributors ?? 0})`
            // `${params.getValue(params.id, 'owner') || ''}`
        },
        {
            field: 'total_reports',
            headerName: 'Reports',
            align: 'center',
            width: 140,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 200,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 200,
        },
        {
            field: 'sync',
            headerName: 'Sync',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const onClickSync = (e: any) => {
                    e.stopPropagation();
                    const grantName: any = params.getValue(params.id, 'name');
                    syncGrant(grantName)
                    .then((resp: any) => {
                        if (resp.status === 200) flashMessage('Grant sync request sent!' , 'success');
                        else throw new Error(resp.status);
                    })
                    .catch((e: any) => flashMessage(`Grant sync failed! ${e.message ?? e}`, 'error'))
                };

                return <div><IconButton aria-label="sync" onClick={onClickSync}>
                    <SyncIcon />
                </IconButton></div>;
            },
        },
    ];

    useEffect(() => {
        if (!grantDialogOpen) {
            init();
        }
    }, [grantDialogOpen])

    useEffect(() => {
        filterGrants(searchGrant, isAppliedChecked)
    }, [grantList])


    const handleClickOpen = (e: any) => {
        console.log(e.row);
        setSelectedGrant(e.row);
        setGrantDialogOpen(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSeason(+event.target.value);
    };

    const handleSearchGrant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchGrant(event.target.value)
        filterGrants(event.target.value, isAppliedChecked)
    }

    const filterGrants = (searchToken: string, appliedOnly: boolean) => {
        const token = searchToken.toLowerCase()
        const grants = grantList.filter(grant =>
            (grant.name?.toLowerCase().includes(token) ||
            grant.owner?.username.includes(token) ||
            grant.title?.includes(token) ||
            grant.funding_address?.includes(token) ||
            grant.matching_round?.title?.includes(token) ||
            (grant.matching_round?.matching_round_id === +token)
            ) && (!appliedOnly || grant.roundState !== '')
        ) ?? []
        setGrantsToDisplay( grants )
    }

    const flashMessage = ( message: String, type: VariantType = 'success' ) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: type
        });
    }

    const handleRequestKYC = () => {

        requestKYC().then((resp: any) => {
            if (resp.status === 200) flashMessage('KYC Request Successful!', "success");
            else throw new Error(`Error ${resp.status}`);
        }).catch((err: any) => {
            flashMessage(`User Request Failed! ${err?.message ?? err}`, "error");
        });
    }

    const handleSyncGrants = () => {

        syncGrants().then((resp: any) => {
            if (resp.status === 200) flashMessage('Grants Sync Successful!', "success");
            else throw new Error(`Error ${resp.status}`);
        }).catch((err: any) => {
            flashMessage(`Grants Sync Failed! ${err?.message ?? err}`, "error");
        });
    }

    const handleAppliedChange = (event: any) => {
        setIsAppliedChecked(event.target.checked);
        filterGrants(searchGrant, event.target.checked)
    };

    const init = async () => {

        try {
            const [grants, reports, kyc, seasons, chainGrants, chainRounds] = await Promise.all([
                loadAllGrants(),
                loadAllAbuseReports(),
                loadAllKycStates(),
                loadAllSeasons(),
                pomelo.get_grants(),
                pomelo.get_rounds()
            ].map(p => p.catch(e => null)));
            console.log('🍌', { grants, reports, seasons })
            if(!grants) throw new Error("Failed to load grants");
            if(kyc === null) flashMessage('Failed to load KYC', "warning");
            if(!chainGrants) flashMessage('Failed to load grants chain states', "warning");
            if(!chainRounds) flashMessage('Failed to load chain rounds', "warning");
            if(reports === null) flashMessage('Failed to load reports', "warning");
            if(seasons === null) flashMessage('Failed to load seasons', "warning");

            // selected season id - just the last one in the list
            const selectedSeasonId = seasons[seasons.length - 1]?.season_id ?? 0;

            const activeRounds = chainRounds.filter((round: any) => round.season_id === selectedSeasonId);

            const chainGrantStates = chainGrants?.reduce((acc: {[key: string]: string}, cur: any) => {
                    acc[cur.id] = cur.status;
                    return acc;
                }, {}) ?? {};

            const reducedGrants: any = grants.reduce((prevValue: any, grant: any) => {
                grant.id = grant.name
                grant.kyc = kyc?.find((user: any) => user.eosn_id === grant.owner?.eosn_id ?? "unknown")?.kyc?.state ?? ""
                const filteredReports: any = reports?.filter((report: any) => {
                    return grant.name === report.reported_grant.name
                }) ?? 0
                grant.reports = filteredReports
                grant.total_reports = filteredReports.length
                grant.chainState = (chainGrantStates[grant.name] ?? "missing").toUpperCase();
                grant.roundState = grant.matching_round?.season_id === selectedSeasonId ? grant.matching_round.state : ''
                grant.chainRounds = activeRounds.filter((round: pomelo.Round) => round.grant_ids.includes(grant.name)).map((round: pomelo.Round) => round.round_id);
                return [...prevValue, grant]
            }, [])

            console.log('🍈', { reducedGrants })
            setGrantList(reducedGrants)
            setSeasonList(seasons)
            setSelectedSeason(selectedSeasonId);  //for now just use the latest season
        }
        catch (err) {
            flashMessage('Failed to load grants', "error");
        }
    }

    return <div style={{ height:"calc(100vh - 64px)" }}>
            <GrantDialog grant={selectedGrant} grantList={grantList} season={seasonList.find((s: any) => s.season_id === selectedSeason)}/>
            <Grid container display="flex" flexDirection="column">
                <Grid item style={{ width: '100%', margin: "auto", padding: "0px 30px 0px 30px" }}>
                    <Typography variant={"h4"} style={{ fontSize: "30px", fontWeight: 400, padding: "24px", color: "#021A4D" }}>Pomelo Grants</Typography>
                    <Grid container>
                        <Grid item>
                            <Box
                                component="form"
                                sx={{
                                    // '& .MuiTextField-root': { m: 1, width: '25ch', display: 'flex', justifyContent: 'flex-start' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="outlined-select-round"
                                    select
                                    disabled
                                    label="Active Season"
                                    value={selectedSeason ?? ""}
                                    onChange={handleChange}
                                    style={{ minWidth: "150px"}}
                                    // helperText="Please select the current round"
                                >
                                    {seasonList.map((season: any) => (
                                        <MenuItem key={season.season_id} value={season.season_id}>
                                            {season.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item style={{ paddingLeft: "16px" }}>
                            <TextField id="outlined-search-grant-id" label="Search Grants" variant="outlined" value={searchGrant} onChange={handleSearchGrant}/>
                        </Grid>
                        <Grid item style={{ paddingLeft: "16px", display: 'flex', alignItems: 'center' }}>
                            <Checkbox checked={isAppliedChecked} onChange={handleAppliedChange} color="primary" />
                            <Typography variant="body1">Show Applied Only</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Card style={{ margin: "20px 30px" }} sx={{
                    '& .status': {
                        background: "#B9BDDD",
                        color: "white",
                        borderRadius: "12px",
                        padding: "0 10px",
                        lineHeight: "26px"
                    },
                    '& .PUBLISHED': {
                        background: "#18ab6b",
                    },
                    '& .APPROVED': {
                        background: "#18ab6b",
                    },
                    '& .BANNED': {
                        background: "#000000",
                    },
                    '& .DENIED': {
                        background: "#FF595E",
                    },
                    '& .PENDING': {
                        background: "#fcaf4a",
                    },
                    '& .ACTION_REQUIRED': {
                        background: "#fc6e4a",
                    },
                }}>
                    <Grid item style={{ cursor: 'pointer', height: 750, width: '100%', margin: "auto" }}>
                        <StyledDataGrid
                            rows={grantsToDisplay}
                            columns={columns}
                            pageSize={GRANTS_PER_PAGE}
                            rowsPerPageOptions={[GRANTS_PER_PAGE]}
                            // sortModel={sortModel}
                            // onSortModelChange={(model) => setSortModel(model)}
                            // checkboxSelection
                            onRowClick={(e) => handleClickOpen(e)}
                            disableSelectionOnClick
                            componentsProps={{ panel: {as: Modal}}}
                            // filterModel={{
                            //     items: [{ columnField: 'state', operatorValue: 'is', value: 'PUBLISHED' },
                            //             // { columnField: 'roundState', operatorValue: 'is', value: 'DENIED' }
                            //         ],
                            // }}
                        />
                    </Grid>
                    <div style={{ textAlign: "center", padding: "20px 0" }}>
                        <Button variant="contained" style={{ backgroundColor: "#18AB6A", borderRadius: "25px", margin: "5px" }} startIcon={<SyncIcon />} onClick={handleRequestKYC}>
                            Request KYC
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: "#18AB6A", borderRadius: "25px", margin: "5px" }} startIcon={<SyncIcon />} onClick={handleSyncGrants}>
                            Sync Grants
                        </Button>
                    </div>
                </Card>

            </Grid>
            {/* <GrantsTableNew grantList1={grantList}/> */}
        </div>
}