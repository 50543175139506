class LocalStorage {

    static AuthSession = "authSession";
    static User = "user";

    static storeUserOnLocalStorage = (isUser: any, user: any, accessToken: any) => {
        localStorage.setItem('isUser', isUser);
        localStorage.setItem('authSession', accessToken);
        localStorage.setItem('user', user);
        // localStorage.setItem('token', isUser ? token : '');
        // localStorage.setItem('user', isUser ? userObject : '');
    };

    static removeUserFromLocalStorage = () => {
        localStorage.setItem('didRefresh', "false");
        localStorage.setItem('isUser', "false");
        localStorage.removeItem('authSession');
    };

    static getAuthSession() {
        return localStorage.getItem(this.AuthSession);
    }

}

export default LocalStorage;