import { atom } from "recoil";
import {IGrantList, SeasonInfo} from '../utils/interfaces';

export const grantDialogOpenState = atom({
  key: "GRANT_DIALOG_OPEN_STATE",
  default: false,
});

export const isUserState = atom({
  key: "IS_USER_STATE",
  default: localStorage.getItem("isUser") === "true" ? true : false,
});

export const ReportListState = atom({
  key: "REPORT_LIST_STATE",
  default: [],
});

export const GrantListState = atom({
  key: "GRANT_LIST_STATE",
  default: Array<IGrantList>(),
});

export const RoundListState = atom({
  key: "ROUND_LIST_STATE",
  default: [],
});

export const seasonDialogOpenState = atom({
  key: "SEASON_DIALOG_OPEN_STATE",
  default: false,
});

export const SeasonListState = atom({
  key: "SEASON_LIST_STATE",
  default: Array<SeasonInfo>(),
});


export const roundDialogOpenState = atom({
  key: "ROUND_DIALOG_OPEN_STATE",
  default: false,
});