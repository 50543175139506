import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { RefreshOutlined } from '@mui/icons-material';
import { getBountySkills, syncBounties, updateBountySkills } from "../services/admin";
const Bounties = () => {
    const [newSkill, setNewSkill] = useState("");
    const [skills, setSkills] = useState<Array<{ id: number, name: string }>>(); // ["skill1", "skill2"
    const handleSyncBounties = () => {
        console.log("Syncing bounties...");
        syncBounties();
    }

    const handleAddSkill = () => {
        console.log(newSkill)
        updateBountySkills(newSkill).then((res: any) => {
            console.log("res", res.data)
            loadBountySkills();
        })
    }

    const loadBountySkills = async () => {
        getBountySkills().then((res: any) => {
            console.log("res", res.data)
            setSkills(res.data.data);
        });
    }
    useEffect(() => {
        loadBountySkills();
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex" }}>
                <Button variant="contained" style={{ backgroundColor: "#19925d" }} onClick={handleSyncBounties}><RefreshOutlined /> Sync Bounties</Button>
            </Box>
            <Box style={{ padding: "24px 0px" }}>
                <TextField onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewSkill(event.target.value);
                }} />
                <Button variant="contained" style={{ backgroundColor: "#19925d", marginLeft: "24px" }} onClick={handleAddSkill}>Add Skill</Button>
            </Box>
            {skills && skills.map((skill) => {
                return <Typography style={{ fontSize: "16px" }} key={skill.id}>{skill.name}</Typography>
            })}
            {/* {JSON.stringify(skills)} */}

        </Box>
    )
}

export default Bounties;