// @ts-nocheck
import { FC } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import SeasonsTable from '../components/SeasonsTable';
import { useRecoilState } from "recoil";
import { isUserState } from "../atoms/atoms";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        "& .MuiTypography-body1": {
            fontFamily: "inherit",
        },
    },

    checkMarkLogo: {
        color: "#0a0",
    },
    badge: {
        display: "block",
        right: "12px",
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            right: "15px",
        },
    },
}));

const Reports: FC<UserProps> = () => {
    const classes = useStyles();
    const [isUser, ] = useRecoilState(isUserState);

    return (
        <div className={classes.container} style={{ borderRadius: "25px 25px 0px 0px", backgroundColor: "#FBEFDF" }}>
        {isUser ? <SeasonsTable /> :
        <div className={classes.welcomeDiv}>
            <h1 className={classes.welcomeHeading}>Welcome to Pomelo Admin Portal!</h1>
            <img alt="pomeloLogo" src={PomeloLogo} className={classes.welcomeImage}/>
        </div>
        }
        </div>
    );
};

export default Reports;
