import { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';

interface LoginButtonProps {
  label: string,
  type?: number
}

const LoginButton: FC<LoginButtonProps> = ({ label, type }) => {
  // const { loginWithRedirect } = useAuth0();
  const { loginWithRedirect, getIdTokenClaims } = useAuth0();
  // const auth0Domain: any = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: any = process.env.REACT_APP_AUTH0_AUDIENCE;
  const domain: any = process.env.REACT_APP_DOMAIN;
  const responseType: any = "code";
  const redirectUri = `${domain}`;

  const handleClickSignin = async () => {

    await loginWithRedirect({
      client_id: clientId,
      response_type: responseType,
      audience: audience,
      redirectUri: redirectUri,
      scope:
        "openid email profile read:current_user update:current_user_identities",
    });

    return await getIdTokenClaims();
  }
  return (
    <Button
      variant={type === 1 ? "text" : "contained"}
      style={type === 1 ? { backgroundColor: "#FFFFFF00", color: "#FFF" } :
        {
          backgroundColor: "#18ab6b",
          color: "#FFF",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "25px",
          paddingRight: "25px",
          borderRadius: "24px"
        }}
      onClick={handleClickSignin}
    >
      <PowerSettingsNew />&nbsp;
      {label}
    </Button>
  );
};

export default LoginButton;
