import { Route, Routes } from 'react-router-dom';
// import "./App.css";
import { NavBar, NavDrawer } from "./components";
import { Home, Page404, Reports, Users, Seasons, Rounds } from "./pages/Index";
import { useRecoilState } from 'recoil';
import { isUserState } from "./atoms/atoms";
import Bounties from './pages/Bounties';


function PageRoutes() {
    const [isUser, setIsUser] = useRecoilState(isUserState);
    // useEffect(() => {
    //     //check if isUser

    // }, [])

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <NavBar />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {isUser ? <NavDrawer /> : <></>}
                <div style={{ width: "100%", backgroundColor: "#FFF" }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/grants" element={<Home />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/seasons" element={<Seasons />} />
                        <Route path="/rounds" element={<Rounds />} />
                        <Route path="/bounties" element={<Bounties />} />
                        {/* <ProtectedRoute path={NAVIGATION_OPTIONS.home} element={Home} /> */}
                        {/* <Route exact path="/reports" component={Reports} /> */}
                        {/* <ProtectedRoute path="/auth/login" element={Home} /> */}
                        <Route path="*" element={<Page404 />}>

                        </Route>
                    </Routes>

                </div>
            </div>
        </div>
    );
}

export default PageRoutes;

