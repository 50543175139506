import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="container" style={{ paddingTop: "300px", borderRadius: "25px 25px 0px 0px", backgroundColor: "#FBEFDF", textAlign: "center" }}>
      <h1>404</h1>
      <p>Page Does Not Exist</p>
      <p style={{ margin: "auto" }}>
        <Link to="/">Return Home</Link>
      </p>
    </div>
  );
};

export default Page404;
